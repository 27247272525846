// src/components/RegisterSite.jsx
import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Bots from '../../assets/images/curriculo_jobs2.webp';
import SeparatorBar from '../../components/Separator/SeparatorBar';
import Avatar from '../../assets/icons/avatar-do-usuario.png';

import 'react-toastify/dist/ReactToastify.css';
import './RegisterSite.css';

const RegisterSite = ({ onSwitchToLogin, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register-site`, {
        name,
        email,
      });

      if (response.status === 201) {
        setSuccess('Usuário registrado com sucesso! Verifique seu e-mail para obter a senha.');
        toast.success('Usuário registrado com sucesso! Verifique seu e-mail para obter a senha.', {
          position: 'top-center'
        });

        // Aguarda 3 segundos e então retorna para tela de login
        setTimeout(() => {
          setSuccess(null);
          onSwitchToLogin();
        }, 3000);
      }
    } catch (err) {
      console.error('Erro ao registrar usuário:', err);
      if (err.response && err.response.data && err.response.data.msg) {
        setError(err.response.data.msg);
      } else {
        setError('Erro ao registrar usuário. Tente novamente.');
      }
    }
  };

  return (
    <div className='container-bg'>
      <ToastContainer />
      <div className="container">
        <div className="content-login mx-auto text-center" style={{ width: "100%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                <h2 className='bots'>
                  Registre-se agora para acessar a área de currículos e dar o próximo passo na sua carreira!
                </h2>
                <div className="col-sm-12 col-md-6">
                  <div className="avatar">
                    <img src={Bots} alt="" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 gap-5 pr-5">
                  {error && <Alert variant="danger">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}

                  <div className='register-title'>
                    <img src={Avatar} alt="" />
                    <h2>Cadastrar no site</h2>
                  </div>
                  <SeparatorBar />
                  <Form onSubmit={handleRegister}>
                    <Form.Group controlId="name">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder='Digite seu nome'
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Digite seu email'
                      />
                    </Form.Group>

                    <Button className='btn-login' variant="primary" type="submit">
                      Registrar
                    </Button>
                  </Form>
                  <div className='signIn'>
                    <p>
                      Já sou cadastrado!{' '}
                      <a
                        href="#login"
                        onClick={(e) => {
                          e.preventDefault();
                          onSwitchToLogin();
                        }}
                      >
                        Login
                      </a>
                    </p>
                  </div>
                </div>
                <p>
                  Se você busca crescimento profissional, desafios estimulantes e deseja fazer a diferença,
                  cadastre-se no site para preencher seu currículo e venha construir o futuro conosco!
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RegisterSite;
