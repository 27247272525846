// src/components/LoginComponent.jsx
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext/AuthContext';

import Login from '../../assets/icons/sessao-de-login.png';
import Bots from '../../assets/images/curriculo_jobs.webp';
import 'react-toastify/dist/ReactToastify.css';
import './LoginComponent.css';
import SeparatorBar from '../Separator/SeparatorBar';

const LoginComponent = ({ onSwitchToRegister, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login-site`, {
        email,
        password
      });

      const { accessToken, user } = response.data;
      if (!accessToken || !user) {
        throw new Error("Resposta inválida do servidor");
      }

      // Armazena token e usuário
      localStorage.setItem('token', accessToken);
      localStorage.setItem('user', JSON.stringify(user));
      setAuth({ token: accessToken, user });

      toast.success('Login bem-sucedido!', { position: 'top-center' });

      // Fecha o modal e redireciona
      setTimeout(() => {
        onClose();             // Fecha o modal
        navigate('/editar-curriculo');
      }, 3000);
    } catch (error) {
      console.error('Erro no login:', error);
      toast.error('Erro ao tentar fazer login. Credenciais inválidas!', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container-bg'>
      <ToastContainer />
      <div className="container">
        <div className="content-login mx-auto text-center" style={{ width: "100%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                <h2 className='bots'>
                  Faça login agora para cadastrar ou visualizar seu currículo e aproveitar grandes oportunidades!
                </h2>

                <div className="col-sm-12 col-md-6 gap-5 pr-5">
                  <div className='login-title'>
                    <img src={Login} alt="" />
                    <h2>Login</h2>
                  </div>
                  <SeparatorBar />
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                      <Form.Label htmlFor="email">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Digite seu email"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="senha">
                      <Form.Label htmlFor="senha">Senha:</Form.Label>
                      <Form.Control
                        type="password"
                        id="senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Digite sua senha"
                        required
                      />
                    </Form.Group>
                    <Button
                      variant="success"
                      disabled={loading}
                      type="submit"
                      className='btn2-logar'
                    >
                      {loading ? 'Entrando...' : 'Entrar'}
                    </Button>
                  </Form>
                  <div className='signIn'>
                    <p>
                      Ainda não é cadastrado?{' '}
                      <a
                        href="#register"
                        onClick={(e) => {
                          e.preventDefault();
                          onSwitchToRegister(); // chama a prop
                        }}
                      >
                        Cadastrar
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="bots">
                    <img src={Bots} alt="" />
                  </div>
                </div>
                <p>
                  Se você busca crescimento profissional, desafios estimulantes e deseja fazer a diferença,
                  faça login para preencher seu currículo e venha construir o futuro conosco!
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
