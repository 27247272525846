// src/components/AuthModal.jsx
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import LoginComponent from '../../components/LoginComponent/LoginComponent';
import RegisterSite from './RegisterSite';

// CSS de transição (veja explicação mais abaixo)
import './AuthModal.css';

function AuthModal({ show, onHide }) {
  // Estado para decidir se mostra a tela de login ou registro
  const [currentView, setCurrentView] = useState('login');

  const handleSwitchToLogin = () => setCurrentView('login');
  const handleSwitchToRegister = () => setCurrentView('register');

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Área de Acesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={currentView}
            timeout={300}
            classNames="fade" // classes CSS usadas para animar
          >
            {currentView === 'login' ? (
              <LoginComponent
                onSwitchToRegister={handleSwitchToRegister}
                onClose={onHide}
              />
            ) : (
              <RegisterSite
                onSwitchToLogin={handleSwitchToLogin}
                onClose={onHide}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </Modal.Body>
    </Modal>
  );
}

export default AuthModal;
