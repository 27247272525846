import React, { useState } from 'react';
import AuthModal from '../../pages/RegisterSite/AuthModal'; // Ajuste o caminho conforme sua organização
import { Button } from 'react-bootstrap';
import './TrabalheConosco.css';

const TrabalheConosco = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleOpenModal = () => {
    setShowAuthModal(true);
  };

  const handleCloseModal = () => {
    setShowAuthModal(false);
  };

  return (
    <div className="trabalhe-container">
      <div className="trabalhe-content">
        <h1>Venha Crescer Conosco na Gigabyte Telecom!</h1>
        <p>
          Somos referência em <strong>Internet</strong>, <strong>TV</strong> e <strong>Telefonia Fixa</strong>, oferecendo soluções
          completas e inovadoras para conectar pessoas e empresas ao que há de melhor em tecnologia.
        </p>
        <p>
          Aqui, você terá a oportunidade de trabalhar em um ambiente dinâmico e inovador, com grandes
          chances de desenvolvimento profissional. Se você é apaixonado por telecomunicações, busca
          desafios e deseja fazer parte de uma equipe que se dedica a entregar o máximo de qualidade aos clientes, esse é o lugar certo!
        </p>

        <ul>
          <li><strong>Internet Fibra Óptica</strong>: Conexões ultrarrápidas e estáveis.</li>
          <li><strong>TV por Assinatura</strong>: Canais e conteúdos para todos os gostos.</li>
          <li><strong>Telefonia Fixa</strong>: Pacotes completos para manter você sempre em contato.</li>
        </ul>

        <p>
          Na Gigabyte Telecom, valorizamos a criatividade, a colaboração e a vontade de aprender.
          Queremos pessoas que gostem de tecnologia, sejam comunicativas e queiram construir uma
          carreira sólida em um setor em constante expansão.
        </p>

        <div className="cta-button-area">
          <Button variant="primary" size="lg" onClick={handleOpenModal}>
            Quero Me Cadastrar / Login
          </Button>
        </div>
      </div>

      {/* Modal de Login/Cadastro */}
      <AuthModal show={showAuthModal} onHide={handleCloseModal} />
    </div>
  );
}

export default TrabalheConosco;
