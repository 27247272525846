import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IncentivoImage from '../../assets/images/Curriculo_cleanup.png';

import './TrabalheConoscoButton.css';

const TrabalheConoscoButton = () => {
  
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Estado para controlar o popup
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible); // Alterna o estado do popup
  };

  return (
    <div 
      className="trabalhe-conosco-container"      
    >
      {/* Botão com ícone */}
      <div
        className="trabalhe-conosco-btn animated-icon"
        onClick={togglePopup} // Exibe/oculta o popup no clique
      >
        <span>Trabalhe Conosco</span>
        <i className="fa fa-briefcase" aria-hidden="true"></i>
      </div>

      {/* Div que aparece no hover */}
      {isPopupVisible && (
        <div className="trabalhe-conosco-popup">
          <img src={IncentivoImage} alt="Trabalhe Conosco" />
          <h3>Venha Trabalhar Conosco!</h3>
          <p>
            Faça parte de uma equipe que valoriza o crescimento e a inovação.
            Clique para explorar as oportunidades disponíveis!
          </p>
          <button
            className="trabalhe-conosco-btn-popup"
            onClick={() => navigate('/work')}
          >
            Eu quero
          </button>
        </div>
      )}
    </div>
  );
};

export default TrabalheConoscoButton;
