import React, { useState } from 'react';
import ComboWrapper from './ComboWrapper/ComboWrapper';
import ComboHearder from './ComboHeader/ComboHeader';

import './ComboTV.css'; // Importando o arquivo de estilos

const ComboTV = () => {

  return (
    <>
      <ComboHearder />
      <ComboWrapper />
    </>
  );
};

export default ComboTV;
